<template>
    <div class="ma-8">
        <v-toolbar color="primary" dark>
            <v-toolbar-title>
                <v-img contain height="45" src="/images/logo-dependecia87-w.png"></v-img>
            </v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="$router.push({path:'/dashboard'})">
                <v-icon>
                    mdi-arrow-left
                </v-icon>
            </v-btn>
        </v-toolbar>
        <v-sheet elevation="2" class="pa-2">
            <v-row>
                <v-col cols=12>
                    <indicadores/>
                </v-col>
                <v-col cols=12 md=6>
                    <ocupacion/>
                </v-col>
                <v-col cols=12 md=6>
                    <cirugias/>
                </v-col>
                <v-col cols=12 md=6>
                    <egresos/>
                </v-col>
                <v-col cols=12 md=6>
                    <consultas/>
                </v-col>
                <v-col cols=12 md=6>
                    <mortalidad/>
                </v-col>
            </v-row>
            <v-row>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
export default {
    components:{
        'indicadores':()=>import('@/views/dashboard/Indicadores'),
        'ocupacion':()=>import('@/views/dashboard/Ocupacion'),
        'cirugias':()=>import('@/views/dashboard/Cirugias'),
        'consultas':()=>import('@/views/dashboard/Consultas'),
        'egresos':()=>import('@/views/dashboard/Egresos'),
        'mortalidad':()=>import('@/views/dashboard/Mortalidad'),
    }
}
</script>

<style>

</style>